<template>
  <div>
    <PageHeader
      title="Sondages"
      description="Sondages à questions multiples"
      :button="{
        icon: 'fas fa-plus-circle',
        text: `Ajouter un sondage à questions multiples`,
        action: createGroup
      }"
    />
    <SurveysSubmenu />

    <PageContent>

      <MVPTable
        :gridData="surveyGroups"
        :options="gridOptions"
        @rowDoubleClicked="handleRowDoubleClick"
        :showAllDetails="false"
      />
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import OneGroup from './Modals/OneGroup'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import SurveysSubmenu from './SurveysSubmenu.vue'
import { mapGetters } from 'vuex'
import MultiListRowDetail from './MultiListRowDetail.vue'

export default {
  name: 'app-surveys-multilist',
  components: {
    PageHeader,
    PageContent,
    MVPTable,
    SurveysSubmenu,
    MultiListRowDetail // eslint-disable-line
  },
  data () {
    return {
      surveyGroups: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'roles',
      'user',
      'domain',
      'application'
    ]),

    isAdmin () {
      return this.roles.includes('admin')
    },
    hasEditionRights () {
      return this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))
    },

    gridOptions () {
      return {
        masterDetail: true,
        detailCellRenderer: 'MultiListRowDetail',
        // getRowHeight: this.getRowHeight,
        detailRowAutoHeight: true,
        suppressContextMenu: true,
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: this.getSurveyColumnDefs(),
            defaultColDef: {
              flex: 1,
              editable: true,
              resizable: true
            },
            onGridReady (params) {
              params.api.setDomLayout('autoHeight')
            }
          },
          getDetailRowData (params) {
            params.successCallback(params.data.surveys)
          }
        },
        columnDefs: [
          {
            cellRenderer: 'agGroupCellRenderer',
            width: 10,
            suppressMenu: true
          },
          {
            headerName: 'Titre',
            field: 'group_title'
          },
          {
            headerName: 'Date début',
            field: 'start_at',
            type: ['dateColumnFilterable'],
            cellRenderer: this.surveyDatesCellRenderer
          },
          {
            headerName: 'Date fin',
            field: 'end_at',
            type: ['dateColumnFilterable'],
            cellRenderer: this.surveyDatesCellRenderer
          },
          {
            headerName: 'Statut',
            field: 'is_enabled',
            cellRenderer: function (params) {
              return params.value ? 'Activé' : 'Désactivé'
            }
          },
          {
            headerName: 'Stats CSV',
            field: 'uuid',
            cellRenderer: params => {
              if (params.value) {
                return `<a href="#" class="download-stats" data-uuid="${params.value}">Stats</a>`
              } else return ''
            },
            width: 200,
            sortable: false,
            hide: !(this.isAdmin || (this.application.app.slug === 'tract' && this.roles.includes('director')))
          }
        ]
      }
    }
  },
  methods: {
    loadSurveysGroups () {
      this.loading = true
      http.get('/domain/surveys?past&multiple').then(response => {
        this.surveyGroups = response.data
          .map(group => ({
            ...group,
            surveyDetails: group.surveys
          }))
          .sort((a, b) => new Date(b.start_at) - new Date(a.start_at))
      }).catch(err => {
        console.log(err)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des groupes de sondages',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        }).finally(() => {
          this.loading = false
        })
      })
    },
    getSurveyColumnDefs () {
      return [
        { headerName: 'Question', field: 'question' }
      ]
    },
    handleRowDoubleClick ({ data }) {
      if (!this.hasEditionRights) return
      this.$buefy.modal.open({
        parent: this,
        component: OneGroup,
        hasModalCard: true,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        props: {
          editGroup: data
        },
        events: {
          updated: () => {
            this.loadSurveysGroups()
            this.$buefy.notification.open({
              duration: 4000,
              message: 'Le sondage à questions multiples a été mis à jour avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          },
          deleted: () => {
            this.loadSurveysGroups()
            this.$buefy.notification.open({
              duration: 4000,
              message: 'Le sondage à questions multiples a été supprimé avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          }
        }
      })
    },
    createGroup () {
      this.$buefy.modal.open({
        parent: this,
        component: OneGroup,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          created: () => {
            this.loadSurveysGroups()
            this.$buefy.notification.open({
              duration: 4000,
              message: 'Le sondage à questions multiples a été créé avec succès !',
              position: 'is-bottom-left',
              type: 'is-success',
              hasIcon: true
            })
          }
        }
      })
    },
    surveyDatesCellRenderer (params) {
      if (params.value === null || params.value === undefined) {
        return 'À définir'
      } else {
        const date = new Date(params.value)
        return new Intl.DateTimeFormat('fr-FR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }).format(date)
      }
    },
    // getRowHeight (params) {
    //   if (params.node && params.node.detail) {
    //     const heightPerSurvey = 19
    //     const heightPerOption = 22

    //     const allSurveysOptionsHeight = params.data.surveys.reduce((totalHeight, survey) => {
    //       const surveyHeight = (survey.options.length * heightPerOption) + heightPerSurvey
    //       return totalHeight + surveyHeight
    //     }, 0)

    //     const gridSizes = params.api.getSizesForCurrentTheme()

    //     return allSurveysOptionsHeight + gridSizes.headerHeight
    //   }
    //   return null
    // },
    handleDownloadClick (event) {
      const target = event.target
      if (target && target.classList.contains('download-stats')) {
        event.preventDefault()

        const uuid = target.getAttribute('data-uuid')
        if (!uuid) return

        this.downloadStats(uuid)
      }
    },
    async downloadStats (uuid) {
      try {
        const response = await http.get(`/surveys/${uuid}/groupstats`, { responseType: 'blob' })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `stats_${uuid}.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Erreur lors du téléchargement des statistiques',
          type: 'is-danger'
        })
      }
    }
  },

  created () {
    this.loadSurveysGroups()
  },

  mounted () {
    this.$nextTick(() => {
      document.addEventListener('click', this.handleDownloadClick)
    })
  },

  beforeDestroy () {
    document.removeEventListener('click', this.handleDownloadClick)
  }

}
</script>
